.purple-text {
  color: #6366f1;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.timeline {
  border-left: 5px solid #6366f1;
  position: relative;
  list-style: none;
}

.timeline .timeline-item {
  position: relative;
  text-align: left;
}

.timeline .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .timeline-item:after {
  background-color: hsl(0, 0%, 90%);
  left: -47px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  content: "";
}

.timeline .completed.timeline-item:after {
  box-shadow: 0px 0px 25px 10px #6366f1;
  background-color: #6366f1;
  left: -47px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  content: "";
}

.timeline:after {
  position: absolute;
  display: block;
  bottom: 0;
}

.timeline:after {
  background-color: #6366f1;
  left: -15px;
  border-radius: 10px;
  height: 5px;
  width: 25px;
  content: "";
}

@media (max-width: 768px) {
  .chainlink-card .card {
    width: 75% !important;
  }
}

@media (max-width: 481px) {
  .chainlink-card .chainlink-logo {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column-reverse;
  }
}
