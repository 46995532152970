//
// user-variables.scss
// Use this to override Bootstrap and Finder variables
//

// Example of a variable override to change Finder background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

.background-gradient {
  color: linear-gradient(#0f0a33, #131023);
}
