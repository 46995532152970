//
// Modal
// --------------------------------------------------


.modal-content {
  padding: {
    right: $modal-inner-padding;
    left: $modal-inner-padding;
  }
}

.modal-header,
.modal-footer {
  padding: {
    right: 0;
    left: 0;
  }
}

.modal-body {
  margin: {
    right: -$modal-inner-padding;
    left: -$modal-inner-padding;
  }
}
