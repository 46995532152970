//
// Pagination
// --------------------------------------------------


.pagination {
  margin-bottom: 0;
  .page-link {
    display: flex;
    align-items: center;
    height: 100%;
    @include border-radius($pagination-border-radius);
    font: {
      size: $pagination-font-size;
      weight: $pagination-font-weight;
    }
    > i {
      margin-top: .0625rem;
      font-size: 1.425em;
    }
  }
  .page-item {
    margin: 0 $pagination-margin;
    &:first-child { margin-left: 0; }
    &:last-child { margin-right: 0; }
    &.active .page-link {
      box-shadow: $pagination-active-box-shadow;
    }
  }
}
.pagination-sm .page-link {
  @include border-radius($pagination-border-radius-sm);
  font-size: $pagination-font-size-sm;
}
.pagination-lg .page-link {
  @include border-radius($pagination-border-radius-lg);
  font-size: $pagination-font-size-lg;
}
