//
// Accordion
// --------------------------------------------------


.accordion-button {
  transition: none;
  font-weight: $accordion-button-font-weight;
  &:not(.collapsed) {
    @include box-shadow(inset 0 ($accordion-border-width * -1) 0 $accordion-border-color);
  }

  // Indicator (Icon)
  &::after {
    width: $accordion-icon-box-size;
    height: $accordion-icon-box-size;
    background: {
      position: center;
      color: $accordion-icon-box-bg;
    }
    transition: all .35s;
    @include border-radius(50%);
  }

  &:not(.collapsed)::after {
    background-color: $accordion-icon-box-active-bg;
    @include box-shadow($accordion-icon-box-active-box-shadow);
  }
}
